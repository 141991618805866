import { format } from 'date-fns'
import { NullUnionType, OptionalType } from '@digital-magic/ts-common-utils'
import { LocalDate } from '@digital-magic/zod-common-schemas'
import { dateFormat } from '@constants/configuration'
import { PaymentDayEnum } from '@api/endpoints/forms'

export const emptyToUndefined = <T>(value: OptionalType<T | ''>): OptionalType<T> => (value === '' ? undefined : value)

export const dateToLocalDate = (date: Date): LocalDate => format(date, dateFormat)
export const nullableDateToOptionalLocalDate = (date: NullUnionType<Date>): OptionalType<LocalDate> =>
  date ? dateToLocalDate(date) : undefined

export const paymentDayLabel = (value: PaymentDayEnum): number => {
  switch (value) {
    case 'FIFTEENTH':
      return 15
    case 'THIRTIETH':
      return 30
  }
}
