import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ClientPersonalData, FormType } from '@api/endpoints/forms/types'
import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useLanguage } from '@hooks/useLanguage'
import { useFormTrackedWrapper } from '@hooks/Tracking'
import { useMutationPersonalData } from '@hooks/ReactQuery/forms'
import { CommonFormProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { FlexContainer } from '@layout/FlexContainer'
import { ResponsiveSection } from '@layout/ResponsiveSection'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { CustomerContactsFormSchema, CustomerContactsFormValues } from '@forms/CustomerContacts/types'
import { CustomerContactsFormInputs } from './CustomerContactsFormInputs'

export type CustomerContactsProps = CommonFormProps<ClientPersonalData> & {
  title?: string
  formType: FormType
  wizardStep?: number
  btnText?: string
  consentChecked?: boolean
}

export const CustomerContacts: React.FC<CustomerContactsProps> = ({
  title,
  formType,
  wizardStep,
  defaultValues,
  onSubmitSuccess,
  disabledControls,
  btnText,
  consentChecked = false
}) => {
  const { t } = useTranslation()
  const [submittedData, setSubmittedData] = React.useState<ClientPersonalData | undefined>(undefined)
  const { language } = useLanguage()

  const handleInvalidPhoneNumber = (): void => {
    form.setError('phoneNumber', { message: t('global.form_errors.invalid_phone_number') })
  }

  const acceptPersonalDataSuccessHandler = async (): Promise<void> => {
    if (submittedData) {
      await onSubmitSuccess(submittedData)
    }
  }

  const acceptPersonalData = useMutationPersonalData({
    onSuccess: acceptPersonalDataSuccessHandler,
    onInvalidPhoneNumber: handleInvalidPhoneNumber
  })

  const form = useZodForm<CustomerContactsFormValues>({
    schema: CustomerContactsFormSchema,
    defaultValues: { ...defaultValues, consent: consentChecked }
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSuccessSubmit = ({ consent, ...values }: CustomerContactsFormValues): void => {
    const data: ClientPersonalData = { language, ...values }
    setSubmittedData(data)
    acceptPersonalData.mutate({ formType, ...data })
  }

  React.useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <form
      onSubmit={
        useFormTrackedWrapper({ formName: formType, formStep: wizardStep }, form, handleSuccessSubmit).handleSubmit
      }
    >
      <FlexContainer vertical>
        <ResponsiveSection title={t('global.forms.contactInfo.contacts')}>
          <CustomerContactsFormInputs
            title={title ?? t('global.forms.customer.title')}
            control={form.control}
            disabledControls={disabledControls || acceptPersonalData.isPending}
          />
        </ResponsiveSection>
        <ActionButtonsContainer noTopGap>
          <Button
            variant="contained"
            color="white"
            type="submit"
            endIcon={<ArrowForwardIcon />}
            disabled={disabledControls}
          >
            {btnText ? btnText : t('global.buttons.continue')}
          </Button>
        </ActionButtonsContainer>
      </FlexContainer>
    </form>
  )
}
