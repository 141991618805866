import * as React from 'react'
import { FN } from '@digital-magic/ts-common-utils'
import { WizardStepper } from './WizardStepper'
import styles from './Wizard.module.css'
import { PageContent } from '@layout/Page'

export type VehicleFormProps<T extends number> = Readonly<{
  steps: ReadonlyArray<T>
  activeStep: T
  buildStepTitle: FN<T, string>
  buildStepCompleted: FN<T, boolean>
  renderStep: FN<T, React.ReactElement>
}>

export const Wizard = <T extends number>({
  steps,
  activeStep,
  buildStepTitle,
  buildStepCompleted,
  renderStep
}: VehicleFormProps<T>): React.ReactElement => {
  return (
    <PageContent>
      <WizardStepper
        className={styles.wizardStepper}
        steps={steps}
        activeStep={activeStep}
        buildTitle={buildStepTitle}
        buildCompleted={buildStepCompleted}
      />
      {renderStep(activeStep)}
    </PageContent>
  )
}
