import * as React from 'react'
import { FN } from '@digital-magic/ts-common-utils'
import { Step, StepLabel, Stepper, StepperProps } from '@mui/material'
import { WizardStep } from './WizardStep'
import { ClassNameProps } from '@controls/types'

export type WizardStepperProps<T extends number> = Omit<StepperProps, 'children'> & ClassNameProps &
  Readonly<{
    steps: ReadonlyArray<T>
    buildTitle: FN<T, string>
    buildCompleted: FN<T, boolean>
  }>

export const WizardStepper = <T extends number>({
  activeStep,
  steps,
  buildTitle,
  buildCompleted,
  className,
  ...stepperProps
}: WizardStepperProps<T>): React.ReactElement => {
  return (
    <Stepper
      classes={{
        root: className
      }}
      activeStep={activeStep}
      {...stepperProps}
    >
      {steps.map((step) => (
        <Step key={step} completed={buildCompleted(step)}>
          <StepLabel
            StepIconComponent={() => (
              <WizardStep stepNumber={step} stepTitle={buildTitle(step)} completed={buildCompleted(step)} />
            )}
          />
        </Step>
      ))}
    </Stepper>
  )
}
