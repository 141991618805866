import * as React from 'react'
import clsx from 'clsx'
import styles from './WizardStep.module.css'
import { FlexContainer } from '@layout/FlexContainer'

type Props = Readonly<{
  stepNumber: number
  stepTitle: string
  completed: boolean
  className?: string
}>

export const WizardStep: React.FC<Props> = ({ stepNumber, stepTitle, completed, className }) => {
  return (
    <FlexContainer vertical alignItemsCenter noGap  className={clsx(styles.wizardStepContainer, className)}>
      <FlexContainer justifyContentCenter alignItemsCenter noGap className={clsx(styles.number, completed && styles.completed)}>{stepNumber}</FlexContainer>
      <div className={styles.title}>{stepTitle}</div>
    </FlexContainer>
  )
}
