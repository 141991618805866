import * as React from 'react'
import { useMediaQuery } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { useTheme } from '@hooks/useTheme'
import { AccordionWithTitle, AccordionWithTitleProps } from '@controls/Accordion'
import { FlexContainer } from '@layout/FlexContainer'

export type ResponsiveSectionProps = AccordionWithTitleProps &
  Readonly<{
    showTitleAlways?: boolean
    showBorder?: boolean
  }>

export const ResponsiveSection: React.FC<ResponsiveSectionProps> = ({
  title,
  showTitleAlways,
  showBorder,
  className,
  children,
  ...accordionProps
}) => {
  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))
  return isDownMd ? (
    <AccordionWithTitle title={title} className={className} {...accordionProps}>
      {children}
    </AccordionWithTitle>
  ) : (
    <FlexContainer vertical bordered={showBorder} className={className}>
      {showTitleAlways === true && <h3 className={formStyles.formSubtitle}>{title}</h3>}
      {children}
    </FlexContainer>
  )
}
