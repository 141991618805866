import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import { AccordionWithTitle } from '@controls/Accordion'
import wizardStyles from '@controls/Wizard/Wizard.module.css'
import { CustomerContacts, CustomerContactsProps } from '@forms/CustomerContacts'
import { SelectedVehicle, SelectedVehicleProps } from '@forms/SelectedVehicle'

export type SelectedVehicleWithCustomerContactsProps = Readonly<{
  vehicleId: OptionalType<VehicleId>
  selectedVehicleOptions: Omit<SelectedVehicleProps, 'vehicleId'>
  customerContactsOptions: CustomerContactsProps
}>

export const SelectedVehicleWithCustomerContacts: React.FC<SelectedVehicleWithCustomerContactsProps> = ({
  vehicleId,
  selectedVehicleOptions,
  customerContactsOptions: { btnText, ...otherCustomerContactsOptions }
}) => {
  const { t } = useTranslation()
  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))

  const customerContactsOptions: CustomerContactsProps = {
    btnText: btnText ?? vehicleId ? t('global.buttons.continue') : t('global.buttons.chooseVehicleFromCatalogue'),
    ...otherCustomerContactsOptions
  }

  return (
    <div className={wizardStyles.wizardContainer}>
      {vehicleId &&
        (isDownMd ? (
          <AccordionWithTitle title={t('global.forms.selectedCar.title')}>
            <SelectedVehicle vehicleId={vehicleId} {...selectedVehicleOptions} />
          </AccordionWithTitle>
        ) : (
          <SelectedVehicle vehicleId={vehicleId} {...selectedVehicleOptions} />
        ))}
      <CustomerContacts {...customerContactsOptions} />
    </div>
  )
}
