import * as React from 'react'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import accordionStyles from './Accordion.module.css'

export type AccordionWithTitleProps = Readonly<{
  title: string | React.ReactNode
}> &
  Omit<AccordionProps, 'title'>

export const AccordionWithTitle: React.FC<AccordionWithTitleProps> = ({ title, children, ...accordionProps }) => {

  return (
    <Accordion
      classes={{
        root: accordionStyles.accordionContainer
      }}
      defaultExpanded={true}
      disableGutters={true}
      sx={{
        '&:before': {
          display: 'none' // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
        }
      }}
      {...accordionProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={accordionStyles.expandIcon} />}>
        <h3 className={accordionStyles.title}>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
