import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { createVehiclesShort, VehicleShort } from '@api/endpoints/vehicles'
import { Button, ButtonProps } from '@mui/material'
import vehicleDefaultImage from '@assets/images/vehicleDefaultImage.png'
import { CommonControlProps } from '@controls/types'
import { useQueryGetVehicleById } from '@hooks/ReactQuery/vehicles'
import { FlexContainer } from '@layout/FlexContainer'
import { VehicleCard } from '@layout/VehicleCard'
import styles from './SelectedVehicle.module.css'
import clsx from 'clsx'

export type SelectedVehicleProps = Readonly<{
  vehicleId: VehicleId
  onChooseAnotherClick?: ButtonProps['onClick']
}> &
  CommonControlProps

export const SelectedVehicle: React.FC<SelectedVehicleProps> = ({
  vehicleId,
  onChooseAnotherClick,
  disabledControls
}) => {
  const { t } = useTranslation()

  const getVehicle = useQueryGetVehicleById(vehicleId)

  const vehicle: OptionalType<VehicleShort> = React.useMemo(
    () => (getVehicle.data ? createVehiclesShort(getVehicle.data) : undefined),
    [getVehicle.data]
  )

  return vehicle ? (
    <FlexContainer noGap className={clsx(styles.selectedVehicle, 'cardsSmUnsetHeight')}>
      <img src={vehicle.thumbnailImageUrl ?? vehicleDefaultImage} className={styles.image} alt="Selected vehicle" />
      <VehicleCard vehicle={vehicle} className={styles.card}>
        {onChooseAnotherClick && (
          <Button
            variant="contained"
            color="white"
            fullWidth
            className={styles.chooseBtn}
            onClick={onChooseAnotherClick}
            disabled={disabledControls}
          >
            {t('global.forms.selectedCar.chooseAnotherCar')}
          </Button>
        )}
      </VehicleCard>
    </FlexContainer>
  ) : null
}
