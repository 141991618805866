import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatEuroInEstonian, formatNumberInEstonian } from '@model/language'
import { BuyoutCommissionRequest, ClientPersonalData, TopicEnum } from '@api/endpoints/forms'
import { VehicleId } from '@api/endpoints'
import formStyles from '@assets/css/forms.module.css'
import wizardStyles from '@controls/Wizard/Wizard.module.css'
import { FormConfirmationMessagePanel } from '@controls/panels'
import { FlexContainer } from '@layout/FlexContainer'
import { ResponsiveSection } from '@layout/ResponsiveSection'
import { DealerContacts } from '@layout/DealerContacts'
import { ContactForm } from '@forms/ContactForm'
import { SelectedVehicle } from '@forms/SelectedVehicle'
import { ContactFormView } from '@forms/ContactFormView'

export type WizardConfirmationSectionProps = Readonly<{
  onReset: () => void
  topic: TopicEnum
  vehicleId?: VehicleId
  clientData?: ClientPersonalData
  vehicleData?: BuyoutCommissionRequest
}> &
  React.PropsWithChildren

export const WizardConfirmationSection: React.FC<WizardConfirmationSectionProps> = ({
  onReset,
  topic,
  vehicleId,
  clientData,
  vehicleData,
  children
}) => {
  const { t } = useTranslation()

  return (
    <div className={wizardStyles.wizardContainer}>
      {vehicleId && (
        <ResponsiveSection title={t('global.forms.selectedCar.title')} showTitleAlways>
          <SelectedVehicle vehicleId={vehicleId} />
        </ResponsiveSection>
      )}
      {clientData && (
        <ResponsiveSection title={t('global.forms.contactInfo.contacts')} showTitleAlways showBorder>
          <ContactFormView data={clientData} />
        </ResponsiveSection>
      )}
      {vehicleData && (
        <ResponsiveSection title={t('pages.exchange.form.offeredCar')} showTitleAlways showBorder>
          <FlexContainer vertical halfWidth>
            <h3 className={formStyles.formSubtitle}>
              {`${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`}
            </h3>
            <span>{vehicleData.registrationNumber}</span>
            <span>
              {' '}
              {t('components.cards.vehicle.odometer-reading', {
                odometerReading: formatNumberInEstonian(vehicleData.mileage)
              })}
            </span>
            <span>{formatEuroInEstonian(vehicleData.requestedPrice)}</span>
          </FlexContainer>
        </ResponsiveSection>
      )}
      {children}
      <FormConfirmationMessagePanel onReset={onReset} />
      <ContactForm topic={topic} />
      <DealerContacts />
    </div>
  )
}
