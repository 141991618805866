import * as React from 'react'
import clsx from 'clsx'
import { ClientPersonalData } from '@api/endpoints/forms/types'
import formStyles from '@assets/css/forms.module.css'
import styles from './ContactFormView.module.css'
import { FlexContainer } from '@layout/FlexContainer'

export type ContactFormViewProps = Readonly<{
  title?: string
  data: ClientPersonalData
  className?: string
}>

export const ContactFormView: React.FC<ContactFormViewProps> = ({ title, data, className }) => (
  <FlexContainer vertical responsiveGap className={clsx(styles.contactsContainer, className)}>
    {title && <h3 className={clsx(formStyles.formSubtitle, styles.subtitle)}>{title}</h3>}
    <span>{data.name}</span>
    <span>{data.phoneNumber}</span>
    <span>{data.email}</span>
    <span>{data.additionalInfo}</span>
  </FlexContainer>
)
